import { lazy, Suspense } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import LoadingPage from "components/LoadingPage"
import MainLayout from "layouts/main"
import AuthGuard from "guards/AuthGuard"
import GuestGuard from "guards/GuestGuard"

import ServicesGuard from "guards/ServicesGuard"
import ServicesLayout from "layouts/services"

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  )

export function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: "services",
      element: (
        <ServicesGuard>
          <ServicesLayout />
        </ServicesGuard>
      ),
      children: [
        {
          path: "installation",
          element: <DeviceStatus />
        }
      ]
    },
    {
      path: "main",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "card",
          element: <Card />
        },
        {
          path: "staff",
          element: <Staff />
        },
        {
          path: "vehicle",
          element: <Vehicle />
        },
        {
          path: "driver",
          element: <Driver />
        },
        {
          path: "device",
          element: <Device />
        },
        {
          path: "firmware",
          element: <Firmware />
        },
        {
          path: "customer",
          element: <Customer />
        },
        // {
        //   path: "customer",
        //       element: <Customer />
        // },
        // {
        //   path: "company",
        //       element: <Company />
        // },
        {
          path: "trip-management",
          children: [
            {
              path: "",
              element: <TripManagement />
            },
            {
              path: "form/edit/:id",
              element: <TripManagementForm />
            }
          ]
        },
        {
          path: "route",
          children: [
            {
              path: "",
              element: <Route />
            },
            {
              path: "form/create",
              element: <RouteForm />
            },
            {
              path: "form/edit/:id",
              element: <RouteForm />
            }
          ]
        },
        {
          path: "location",
          children: [
            {
              path: "",
              element: <Location />
            }
          ]
        },
        {
          path: "reports",
          children: [
            {
              path: "log",
              element: <LogReport />
            }
          ]
        },
        {
          path: "dashboard",
          element: <MainDashboard />
        },
        {
          path: "user",
          children: [
            {
              path: "",
              element: <User />
            },
            {
              path: "form/create",
              element: <UserForm />
            },
            {
              path: "form/edit/:id",
              element: <UserForm />
            }
          ]
        },
        {
          path: "group",
          children: [
            {
              path: "",
              element: <Group />
            },
            {
              path: "form/create",
              element: <GroupForm />
            },
            {
              path: "form/edit/:id",
              element: <GroupForm />
            }
          ]
        },
        {
          path: "permission",
          children: [
            {
              path: "",
              element: <Permission />
            },
            {
              path: "form/create",
              element: <PermissionForm />
            },
            {
              path: "form/edit/:id",
              element: <PermissionForm />
            }
          ]
        }
      ]
    },
    {
      path: "*",
      children: [
        {
          path: "404",
          element: <NotFoundPage />
        },
        {
          path: "*",
          element: <Navigate to="/404" replace />
        }
      ]
    },

    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      index: true
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />
    }
  ])
}

// Authentication
const Login = Loadable(lazy(() => import("pages/auth/Login")))

// Card
const Card = Loadable(lazy(() => import("pages/main/Card")))

// Staff
const Staff = Loadable(lazy(() => import("pages/main/Staff")))

// Vehicle
const Vehicle = Loadable(lazy(() => import("pages/main/Vehicle")))

// Driver
const Driver = Loadable(lazy(() => import("pages/main/Driver")))

// Device
const Device = Loadable(lazy(() => import("pages/main/Device")))

// User
const User = Loadable(lazy(() => import("pages/main/User")))
// Create User
const UserForm = Loadable(lazy(() => import("pages/main/User/components/Form")))

// // Customer
// const Customer = Loadable(lazy(() => import("pages/main/Customer")))

// // Company
// const Company = Loadable(lazy(() => import("pages/main/Company")))

// report
// logreport
const LogReport = Loadable(lazy(() => import("pages/main/reports/Log")))

// dashboard
// main dasboard
const MainDashboard = Loadable(lazy(() => import("pages/main/Dashboard")))

// Main
const NotFoundPage = Loadable(lazy(() => import("pages/NotFound")))

// Route
const Route = Loadable(lazy(() => import("pages/main/Route")))
// Create Route
const RouteForm = Loadable(lazy(() => import("pages/main/Route/components/Form")))

// Location
const Location = Loadable(lazy(() => import("pages/main/Location")))

// Permission
const Permission = Loadable(lazy(() => import("pages/main/Permission")))
// Create Permission
const PermissionForm = Loadable(lazy(() => import("pages/main/Permission/components/Form")))

// Group
const Group = Loadable(lazy(() => import("pages/main/Group")))
// Create Group
const GroupForm = Loadable(lazy(() => import("pages/main/Group/components/Form")))

// firmware
const Firmware = Loadable(lazy(() => import("pages/main/Firmware")))

// Services

// device status
const DeviceStatus = Loadable(lazy(() => import("pages/services/Installation")))

// Trip Management
const TripManagement = Loadable(lazy(() => import("pages/main/TripManagement")))
//  Form trip management
const TripManagementForm = Loadable(lazy(() => import("pages/main/TripManagement/components/FormManageTrip")))


// customer
const Customer = Loadable(lazy(() => import("pages/main/Customer")))